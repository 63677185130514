.ui-btn-2 {
background: linear-gradient(0deg, #FF9800, #FF9800);
}

button.ui-btn:disabled {
  border-image-source: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.thin-line {
  border: 1px solid rgba(255, 255, 255, 0.1)
}



