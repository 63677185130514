body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  // outline: 1px dashed #999;
}

a {
  text-decoration: none;
  color: inherit;
}

button:active {
  opacity: 0.5;
}

button:disabled {
  opacity: 1;
}

button {
  cursor: pointer;
}

.App {
  font-family: "Kanit", sans-serif;
}

select:focus,
input:focus {
  outline: none;
}

.multi-select {
  outline: none;
  div:hover {
    border-color: gray;
    outline: none;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}


.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}