.custom-shadow {
  filter: drop-shadow(0px 10px 11px rgba(128, 128, 128, 0.626));
}

video {
  &::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }
}
